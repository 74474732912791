import React from 'react';
import { FaDiscord, FaUsers, FaCalendarAlt, FaChartLine } from 'react-icons/fa';

const DiscordCommunityManagerCard = ({ darkMode }) => {
  return (
    <div className={`h-full animate-fade-in-up max-w-sm rounded-lg overflow-hidden shadow-2xl ${darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"} p-6 m-4 transition duration-500 ease-in-out transform relative`}>
      <div className="absolute top-4 left-4 right-4 flex items-center justify-between">
        <div className="w-12 h-12 bg-blue-500 rounded-full flex items-center justify-center">
          <FaDiscord className="text-3xl text-white" />
        </div>
        <div className="font-bold text-xl text-center flex-1 whitespace-nowrap">
          Discord Community<br/> Manager & Moderator
        </div>
      </div>
      <div className="mt-16">
        <p className="text-lg">Experienced in managing, moderating and growing engaging Discord communities.</p>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex items-center">
            <FaUsers className="text-3xl mr-2" />
            <span className="text-lg">120.000+ members</span>
          </div>
          <div className="flex items-center">
            <FaCalendarAlt className="text-3xl mr-2" />
            <span className="text-lg">50+ events</span>
          </div>
          <div className="flex items-center col-span-2">
            <FaChartLine className="text-3xl mr-2" />
            <span className="text-lg">Successful growth</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscordCommunityManagerCard;