import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import CircularImage from './CircularImage';
import Projects from './Projects';
import FullStackDeveloperCard from './fullstackdevelopercard';
import DiscordCommunityManagerCard from './discordcommunitymanagercard';

function App() {
  const [darkMode, setDarkMode] = useState(true);

  return (
    <Router>
      <div className={`transition-all duration-500 min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-200 text-black'}`}>
        <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />
        <Routes>
          <Route path="/" element={
            <main className="p-4 flex flex-col items-center">
              <CircularImage />
              <p className="text-2xl font-bold mt-8">Hey, I'm IceLavaMan👋</p>
              <h2 className="text-60xl font-bold mt-5 mb-6">Under development...</h2>
              <div className="flex flex-col md:flex-row justify-center">
                <div className="flex-1 m-4">
                  <div className="h-full">
                    <FullStackDeveloperCard darkMode={darkMode} />
                  </div>
                </div>
                <div className="flex-1 m-4">
                  <div className="h-full">
                    <DiscordCommunityManagerCard darkMode={darkMode} />
                  </div>
                </div>
              </div>
            </main>
          } />
          <Route path="/projects" element={<Projects darkMode={darkMode} setDarkMode={setDarkMode} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
