import React from "react";

    import Chatbot from "https://cdn.jsdelivr.net/npm/flowise-embed/dist/web.js"
    Chatbot.init({
        chatflowid: "ed70a083-9365-4da4-9bbf-0306e023ff57",
        apiHost: "https://bots.icelavaman.com",
        chatflowConfig: {
            // topK: 2
        },
        theme: {
            button: {
                backgroundColor: "#3B81F6",
                right: 20,
                bottom: 20,
                size: 48, // small | medium | large | number
                dragAndDrop: true,
                iconColor: "white",
                customIconSrc: "https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg",
            },
            tooltip: {
                showTooltip: true,
                tooltipMessage: 'Hi There 👋!',
                tooltipBackgroundColor: 'black',
                tooltipTextColor: 'white',
                tooltipFontSize: 16,
            },
            chatWindow: {
                showTitle: true,
                title: 'Flowise Bot',
                titleAvatarSrc: 'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
                showAgentMessages: true,
                welcomeMessage: 'Hello! This is custom welcome message',
                errorMessage: 'This is a custom error message',
                backgroundColor: "#ffffff",
                height: 700,
                width: 400,
                fontSize: 16,
                poweredByTextColor: "#303235",
                botMessage: {
                    backgroundColor: "#f7f8ff",
                    textColor: "#303235",
                    showAvatar: true,
                    avatarSrc: "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/parroticon.png",
                },
                userMessage: {
                    backgroundColor: "#3B81F6",
                    textColor: "#ffffff",
                    showAvatar: true,
                    avatarSrc: "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png",
                },
                textInput: {
                    placeholder: 'Type your question',
                    backgroundColor: '#ffffff',
                    textColor: '#303235',
                    sendButtonColor: '#3B81F6',
                    maxChars: 250,
                    maxCharsWarningMessage: 'You exceeded the characters limit. Please input less than 50 characters.',
                    autoFocus: true, // If not used, autofocus is disabled on mobile and enabled on desktop. true enables it on both, false disables it on both.
                    sendMessageSound: true,
                    // sendSoundLocation: "send_message.mp3", // If this is not used, the default sound effect will be played if sendSoundMessage is true.
                    receiveMessageSound: true,
                    // receiveSoundLocation: "receive_message.mp3", // If this is not used, the default sound effect will be played if receiveSoundMessage is true. 
                },
                feedback: {
                    color: '#303235',
                },
                footer: {
                    textColor: '#303235',
                    text: 'Powered by',
                    company: 'IceLavaMan',
                    companyLink: 'https://icelavaman.com',
                }
            }
        }
    })

const Projects = ({ darkMode }) => {
  return (
    <div
      className={`transition-all duration-500 min-h-screen ${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-200 text-black"
      }`}
    >
      <main className="p-4 flex flex-col items-center space-y-8">
        <div
          className={`transition-all duration-500 ${
            darkMode ? "bg-gray-800 text-white" : "text-gray-600 bg-white"
          } w-full max-w-4xl p-6 rounded-lg shadow-lg`}
        >
          <div className="flex items-center mb-6">
            <img
              src="https://protator.xyz/protator.webp"
              alt="Protator Logo"
              className="w-24 h-24 rounded-full mr-6"
            />
            <div>
              <h2 className="text-3xl font-bold mb-2">Protator</h2>
              <p className="text-xl">Discord CAPTCHA Verification Bot</p>
            </div>
          </div>
          <p className="text-lg mb-4">
            Introducing <strong>Protator</strong>, the ultimate security
            powerhouse for your Discord server! With its robust protection and
            unique CAPTCHA system, Protator is your server's guardian angel.
            Effortlessly integrate Protator and experience exceptional results
            in just 2 minutes using the <code>/help</code> command.
          </p>
          <h3 className="text-xl font-bold mb-2">Key Features:</h3>
          <ul className="list-disc pl-6 mb-4 space-y-1">
            <li>
              <strong>Ultimate security</strong> with a unique CAPTCHA system
            </li>
            <li>
              <strong>Audio CAPTCHAs</strong> now available for enhanced
              accessibility
            </li>
            <li>
              <strong>Top CAPTCHA generation time</strong> of less than 120ms
            </li>
            <li>
              <strong>Easy setup</strong> in just 2 minutes using{" "}
              <code>/help</code>
            </li>
            <li>
              <strong>Phishing/Scam URL detection</strong> with reports for mods
              (coming soon, in private beta)
            </li>
            <li>
              <strong>AI Moderation</strong> with AI detection, reports, and
              moderation actions for mods(coming soon, in private beta)
            </li>
          </ul>
          <h3 className="text-xl font-bold mb-2">Technologies Used:</h3>
          <ul className="list-disc pl-6 mb-6 space-y-1">
            <li>
              <strong>Website</strong>:{" "}
              <a
                href="https://protator.xyz"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                protator.xyz
              </a>
              , built with React and Tailwind
            </li>
            <li>
              <strong>Bot</strong>: Developed using Discord.js v14 (Node.js)
            </li>
            <li>
              <strong>Infrastructure</strong>: Utilizes S3-compatible Object
              Storage, MongoDB, and Cloudflare Workers
            </li>
            <li>
              <strong>High Availability</strong>: Complete backup system to
              ensure maximum uptime even if the main server goes down
            </li>
          </ul>
          <div className="flex justify-center">
            <a
              href="https://discord.com/api/oauth2/authorize?client_id=1197074631098630204&permissions=1099780114434&scope=applications.commands%20bot"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Invite Protator to Your Server
            </a>
          </div>
        </div>
        {/* PRISM Live Studio Bot Project Card */}
        <div
          className={`transition-all duration-500 ${
            darkMode ? "bg-gray-800 text-white" : "text-gray-600 bg-white"
          } w-full max-w-4xl p-6 rounded-lg shadow-lg`}
        >
          <div className="flex items-center mb-6">
            <img
              src="https://cdn.discordapp.com/avatars/1175421788826652722/abcfffe609ae6a48568f9272274ca754.webp"
              alt="PRISM Live Studio Bot Logo"
              className="w-24 h-24 rounded-full mr-6"
            />
            <div>
              <h2 className="text-3xl font-bold mb-2">PRISM Live Studio Bot</h2>
              <p className="text-xl">
                Exclusive for the PRISM Live Studio Server
              </p>
            </div>
          </div>
          <p className="text-lg mb-4">
            The <strong>PRISM Live Studio Bot</strong> is a sophisticated
            addition to the PRISM Live Studio Server, designed to enhance user
            support and interaction. Programmed with Discord.js v14, it
            leverages the GPT-4 Turbo to detect support requests in the general
            chat and guide users to the support channel. Additionally, it
            utilizes the OpenAI Assistants API to allow users to ask questions
            about PRISM Live Studio in a dedicated bot channel and receive
            informed responses based on the documentation and GPT-4 Turbo.
          </p>
          <h3 className="text-xl font-bold mb-2">Key Features:</h3>
          <ul className="list-disc pl-6 mb-4 space-y-1">
            <li>
              Uses <strong>GPT-4 Turbo</strong> for detecting support requests
            </li>
            <li>
              Guides users to the <strong>support channel</strong> for
              assistance
            </li>
            <li>
              Employs <strong>OpenAI Assistants API</strong> for user queries
            </li>
            <li>
              Logs all support threads and allows supporters to{" "}
              <strong>claim and mark them as resolved</strong>
            </li>
          </ul>
          <div className="flex justify-center">
            <a
              href="https://discord.gg/prism-live-studio-1090897516960161833"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Check Out the Bot on Discord
            </a>
          </div>
        </div>
        <div
          className={`transition-all duration-500 ${
            darkMode ? "bg-gray-800 text-white" : "text-gray-600 bg-white"
          } w-full max-w-4xl p-6 rounded-lg shadow-lg`}
        >
          <div className="flex items-center mb-6">
            <img
              src="https://ai-joingate.xyz/ai-joingate-bot-logo.webp"
              alt="AI Joingate Logo"
              className="w-24 h-24 rounded-full mr-6"
            />
            <div>
              <h2 className="text-3xl font-bold mb-2">AI Joingate</h2>
              <p className="text-xl">Automated Discord Verification Bot</p>
            </div>
          </div>
          <p className="text-lg mb-4">
            <strong>AI Joingate</strong> is a powerful Discord bot that
            revolutionizes the way new members are verified on your server. By
            leveraging the power of artificial intelligence, AI Joingate engages
            new members in interactive, context-aware conversations to ensure
            they understand your server's rules and culture.
          </p>
          <h3 className="text-xl font-bold mb-2">Key Features:</h3>
          <ul className="list-disc pl-6 mb-4 space-y-1">
            <li>
              <strong>Streamlined Verification Process</strong>: Automated,
              personalized verification experience tailored to your server's
              requirements
            </li>
            <li>
              <strong>Multilingual Support</strong>: Communicates with users in
              their preferred language
            </li>
            <li>
              <strong>Customizable Settings</strong>: Easily customize the
              verification process to fit your server's needs
            </li>
            <li>
              <strong>Enhanced Security and Moderation</strong>: Logs the
              verification process and provides tools to monitor progress and
              identify potential issues
            </li>
            <li>
              <strong>Engaging and Interactive</strong>: Uses advanced AI
              technology to generate intelligent and context-aware responses
            </li>
          </ul>
          <div className="flex justify-center space-x-4">
            <a
              href="https://discord.gg/qQZmcDjnf4"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Join Support Server
            </a>
            <a
              href="https://discord.com/oauth2/authorize?client_id=1233013559365992458&permissions=8&scope=bot"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
            >
              Invite AI Joingate
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Projects;
