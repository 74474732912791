import React, { useState } from 'react';
import './CircularImage.css';

const CircularImage = () => {
  const [highResImageLoaded, setHighResImageLoaded] = useState(false);

  const lowResImageUrl = 'https://res.cloudinary.com/dt8avrhk9/image/upload/q_auto,f_auto,w_100,c_scale/profile_zs1w2z.png';
  const highResImageUrl = 'https://res.cloudinary.com/dt8avrhk9/image/upload/q_auto,f_auto/profile_zs1w2z.png';

  return (
    <div className="w-48 h-48 mx-auto relative">
      <div className="w-full h-full absolute top-0 left-0 rounded-full animate-gradient-x"></div>
      <img
        src={lowResImageUrl}
        alt="Profile"
        className="shadow-lg transition-transform duration-500 w-44 h-44 rounded-full object-cover relative z-10 mx-auto mt-2"
        onLoad={() => setHighResImageLoaded(true)}
        style={{ opacity: highResImageLoaded ? 0 : 1 }}
      />
      <img
        src={highResImageUrl}
        alt="Profile"
        className="shadow-lg transition-transform duration-500 w-44 h-44 rounded-full object-cover absolute z-10 top-2 left-2"
        style={{ opacity: highResImageLoaded ? 1 : 0 }}
      />
    </div>
  );
};

export default CircularImage;
