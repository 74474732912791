import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdOutlineBrightness4, MdOutlineWbSunny } from 'react-icons/md';

const Navbar = ({ darkMode, setDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleDarkMode = () => setDarkMode(!darkMode);

  const menuItems = [
    { label: 'Home', path: '/' },
    { label: 'Projects', path: '/projects' },
  ];

  return (
    <nav className={`transition-all duration-500 ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* IceLavaMan Schriftzug für alle Bildschirmgrößen */}
          <Link to="/" className={`text-4xl font-bold ${darkMode ? 'text-white' : 'text-black'}`}>
            IceLavaMan
          </Link>
          {/* Menüpunkte und Dark Mode-Schalter für Desktop */}
          <div className="hidden md:flex items-center space-x-4">
            {menuItems.map(({ label, path }) => (
              <Link
                key={path}
                to={path}
                className={`px-3 py-2 rounded-md text-sm font-medium ${
                  location.pathname === path
                    ? `${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-200 text-black'}`
                    : `${darkMode ? 'text-gray-300 hover:bg-gray-700 hover:text-white' : 'text-gray-600 hover:bg-gray-100 hover:text-black'}`
                }`}
              >
                {label}
              </Link>
            ))}
            <button
              onClick={toggleDarkMode}
              className={`p-2 rounded-full focus:outline-none ${
                darkMode ? 'bg-gray-600 text-gray-300 hover:text-white' : 'bg-gray-200 text-gray-600 hover:text-black'
              }`}
            >
              {darkMode ? (
                <MdOutlineWbSunny className="h-6 w-6" />
              ) : (
                <MdOutlineBrightness4 className="h-6 w-6" />
              )}
            </button>
          </div>
          {/* Schalter und Menü-Button für mobile Ansicht */}
          <div className="flex md:hidden items-center">
            <button
              onClick={toggleDarkMode}
              className={`p-2 rounded-full focus:outline-none ${
                darkMode ? 'bg-gray-600 text-gray-400 hover:text-white' : 'bg-gray-200 text-gray-600 hover:text-black'
              }`}
            >
              {darkMode ? (
                <MdOutlineWbSunny className="h-6 w-6" />
              ) : (
                <MdOutlineBrightness4 className="h-6 w-6" />
              )}
            </button>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`ml-2 p-2 rounded-md focus:outline-none ${
                darkMode ? 'text-gray-400 hover:text-white hover:bg-gray-700' : 'text-gray-600 hover:text-black hover:bg-gray-100'
              }`}
            >
              <svg
                className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
              <svg
                className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Dropdown-Menü für mobile Ansicht */}
      <div
        className={`${
          isOpen ? 'max-h-60 opacity-100' : 'max-h-0 opacity-0'
        } md:hidden overflow-hidden transition-all duration-500 ease-in-out`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1">
          <div className={`border-b ${darkMode ? 'border-gray-700' : 'border-gray-200'}`}></div>
          {menuItems.map(({ label, path }) => (
            <Link
              key={path}
              to={path}
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                location.pathname === path
                  ? `${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-200 text-black'}`
                  : `${darkMode ? 'text-gray-300 hover:bg-gray-700 hover:text-white' : 'text-gray-600 hover:bg-gray-100 hover:text-black'}`
              }`}
            >
              {label}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;