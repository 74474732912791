import React from 'react';
import { FaReact, FaNodeJs, FaRobot } from 'react-icons/fa';
import { SiMongodb, SiExpress, SiHtml5, SiCss3, SiTailwindcss, SiCloudflare } from 'react-icons/si';

const FullStackDeveloperCard = ({ darkMode }) => {
  return (
    <div className={`h-full animate-fade-in-up max-w-sm rounded-lg overflow-hidden shadow-2xl ${darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"} p-6 m-4 transition duration-500 ease-in-out transform relative`}>
     <div className="absolute top-4 left-4 right-4 flex items-center justify-between">
  <div className="w-12 h-12 bg-blue-500 rounded-full flex items-center justify-center">
    <FaReact className="text-3xl text-white" />
  </div>
  <div className="font-bold text-xl text-center flex-1">
    Full Stack Developer
  </div>
</div>
      <div className="mt-16">
        <p className="text-lg">Crafting modern web apps and seamless user experiences.</p>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex items-center">
            <FaNodeJs className="text-3xl mr-2" />
            <span className="text-lg">Node.js</span>
          </div>
          <div className="flex items-center">
            <SiMongodb className="text-3xl mr-2" />
            <span className="text-lg">MongoDB</span>
          </div>
          <div className="flex items-center">
            <SiExpress className="text-3xl mr-2" />
            <span className="text-lg">Express</span>
          </div>
          <div className="flex items-center">
            <SiHtml5 className="text-3xl mr-2" />
            <span className="text-lg">HTML</span>
          </div>
          <div className="flex items-center">
            <SiCss3 className="text-3xl mr-2" />
            <span className="text-lg">CSS</span>
          </div>
          <div className="flex items-center">
            <SiTailwindcss className="text-3xl mr-2" />
            <span className="text-lg">Tailwind</span>
          </div>
          <div className="flex items-center">
            <SiCloudflare className="text-3xl mr-2" />
            <span className="text-lg">Cloudflare</span>
          </div>
          <div className="flex items-center col-span-2">
            <FaRobot className="text-3xl mr-2" />
            <span className="text-lg">Discord.js Bot Development</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullStackDeveloperCard;